import { default as character_45sheetfzyrNbKX2gMeta } from "/tmp/build_d8839d56/pages/game-elements/character-sheet.vue?macro=true";
import { default as indexnykkNmaJJyMeta } from "/tmp/build_d8839d56/pages/index.vue?macro=true";
import { default as playBfoXHykYMeMeta } from "/tmp/build_d8839d56/pages/play.vue?macro=true";
export default [
  {
    name: "game-elements-character-sheet",
    path: "/game-elements/character-sheet",
    meta: character_45sheetfzyrNbKX2gMeta || {},
    component: () => import("/tmp/build_d8839d56/pages/game-elements/character-sheet.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_d8839d56/pages/index.vue")
  },
  {
    name: "play",
    path: "/play",
    component: () => import("/tmp/build_d8839d56/pages/play.vue")
  }
]